<template>

<table class="uk-table uk-table-hover uk-table-divider">
    <thead>
        <th class="uk-table-shrink">
          <label>
            <input class="uk-checkbox" type="checkbox">
          </label>
        </th>
        <th class="uk-table-expand">Name</th>
        <th class="uk-width-small uk-text-right">Größe</th>
        <th class="uk-width-small uk-text-right">Geändert</th>
        <th style="width: 20px"></th>
    </thead>
    <tbody>
        <tr v-for="folder in folders" :key="folder.name" :class="{'uk-active': isSelected(folder.name)}" @click.prevent="toggleSelect(folder.name)">
            <td>
              <label>
                <input class="uk-checkbox" type="checkbox" name="name" :value="folder.name" v-model="selected" @click.stop>
              </label>
            </td>
            <td>
              <div class="uk-flex uk-flex-middle">
                <span data-uk-icon="folder" class="uk-margin-small-right uk-text-muted"></span>
                <a @click.stop="setPath(folder.path)">{{ folder.name }}</a>
              </div>
              </td>
            <td></td>
            <td></td>
        </tr>

        <tr v-for="file in files" :key="file.name" :class="{'uk-active': isSelected(file.name)}" @click="toggleSelect(file.name)">
            <td>
              <label>
                <input class="uk-checkbox" type="checkbox" name="name" :value="file.name" v-model="selected" @click.stop>
              </label>
              </td>
            <td>
              <div class="uk-flex uk-flex-middle">
                <div class="uk-background-contain uk-margin-small-right uk-background-norepeat" v-if="isImage(file.path)" :style="'width:20px;height:20px;background-image:url(' + file.url + ')'"></div>
                <span v-else data-uk-icon="file-text" class="uk-margin-small-right"></span>
                {{ file.name }}
              </div>
            </td>
            <td class="uk-text-right uk-text-nowrap">{{ file.size }}</td>
            <td class="uk-text-nowrap uk-text-right">{{ file.lastmodified | fromNow }}</td>
            <td class="uk-text-nowrap uk-text-right"><a download :href="downloadUrl(file.path)" target="_blank" class="uk-icon-link" data-uk-icon="cloud-download" data-uk-tooltip="Download"></a></td>
        </tr>

    </tbody>
</table>
</template>

<script>
import * as moment from 'moment'
import { mapGetters } from 'vuex'
moment.locale('de')
export default {
  props: {
    searched: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      selected: []
    }
  },
  computed: {
    ...mapGetters(['customer']),
    folders () {
      // eslint-disable-next-line no-undef
      return _.filter(this.searched, file => {
        return file.mime === 'application/folder'
      })
    },
    files () {
      // eslint-disable-next-line no-undef
      return _.filter(this.searched, file => {
        return file.mime === 'application/file'
      })
    }
  },
  watch: {
    selected () {
      this.$emit('selected', this.getSelected())
    }
  },
  methods: {
    setPath (path) {
      this.$emit('setPath', path)
    },
    getSelected () {
      return this.selected.map(name => {
        // eslint-disable-next-line no-undef
        return _.find(this.searched, s => s.name === name).name
      })
    },
    isSelected (name) {
      return this.selected.indexOf(name.toString()) !== -1
    },
    toggleSelect (name) {
      const index = this.selected.indexOf(name)
      index === -1 ? this.selected.push(name) : this.selected.splice(index, 1)
    },
    isImage (url) {
      return url.match(/\.(?:gif|jpe?g|png|svg|ico)$/i)
    },
    downloadUrl (path) {
      return `https://images.descpro.de/files/${this.customer.id}${path}`
    }
  },
  filters: {
    fromNow (date) {
      return moment(date).fromNow()
    }
  }
}
</script>
